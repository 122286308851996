
import { GetFechamentoMensal } from "@/models/RelatorioFechamentoMensalModel";
import { fechamentoMensal } from "@/services/RelatorioFechamentoMensal";
import { onMounted, ref, reactive } from 'vue'
import { ContentLoader } from "vue-content-loader";
import useAlert from "@/composables/Alert";

export interface Paginate {
    current_page: number;
    total: number;
    to: number;
    last_page: number;
    per_page: number;
    data: GetFechamentoMensal[];
}

export default {
    components: {
        ContentLoader
    },
    setup() {
        const { showTimeAlert } = useAlert();
        const dataTable = ref<GetFechamentoMensal[]>([])
        const search = ref('')
        const delayTimer = ref(0)
        const loading = ref(false)
        const status = ref('Todas as Situação')
        const data: Paginate = reactive({
            current_page: 1,
            total: 0,
            to: 0,
            last_page: 0,
            per_page: 50,
            data: [],
        });        
        const optionStatus = [
            {
                status: 'Atenção'
            },
            {
                status: 'Atualizado'
            },
            {
                status: 'Desatualizado'
            },
            {
                status: 'Todas as Situação'
            }
        ]

        onMounted(() => {
            getFechamentoMensal()
        })

        const getFechamentoMensal = async () => {
            loading.value = true
            try {
                const response = await fechamentoMensal()
                dataTable.value = response
            } catch (error) {
                dataTable.value = []
                showTimeAlert("Não foi possível completar a solicitação", "error")
            }
            pagination()
            loading.value = false
        }

        function pagination(page = 1, filter = false) {
            let dataTableArray: GetFechamentoMensal[] = [];
            console.log(status.value)
            if(status.value != 'Todas as Situação'){
                dataTableArray = dataTable.value.filter((concessionaria) => concessionaria.status == status.value)
                console.log(dataTableArray)
            }else{
                dataTableArray = dataTable.value
            }
                

            if (filter && search.value) {
                dataTableArray = dataTableArray.filter((concessionaria) =>
                    concessionaria.nomeFantasia.toLowerCase().includes(search.value.toLowerCase()) ||
                    concessionaria.nomeUsuario.toLowerCase().includes(search.value.toLowerCase()) ||
                    concessionaria.status.toLowerCase().includes(search.value.toLowerCase())
                );
            } else {
                dataTableArray = dataTable.value;
            }

            data.total = dataTableArray.length;
            data.last_page =
                Math.ceil(dataTableArray.length / data.per_page) ?? 0;

            data.current_page = page;
            const start = data.per_page * (page - 1);
            const end = data.per_page * page;

            if (data.last_page == page) {
                data.to = dataTableArray.length;
            } else if (end > dataTableArray.length) {
                data.to = dataTableArray.length;
            } else {
                data.to = end;
            }

            data.data = dataTableArray.slice(start, end);

            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
        }

        function filterData() {
            clearTimeout(delayTimer.value);
            delayTimer.value = setTimeout(function () {
                pagination(1, true);
            }, 300);
        }

        return {
            dataTable,
            data,
            pagination,
            search,
            filterData,
            loading,
            status,
            optionStatus
        }
    }
}
